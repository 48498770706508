@import '@/styles/theme/variables.scss';

.sectionRow {
  padding-top: $token-spacing-20;
  padding-bottom: $token-spacing-20;
}

.homeSolutionsOverviewSection{
  padding-bottom: $token-spacing-10;
  padding-top: $token-spacing-6;
}

.testimonialSection{
  padding-top: $token-spacing-10;
  padding-bottom: $token-spacing-10;
}

.blockWrapperSection{
  padding-top: $token-spacing-10;
  padding-bottom: $token-spacing-10;
}

.browseDocsSection {
  padding-bottom: $token-spacing-16;
  background-color: $home-browseDocsSection-bgcolor !important;
}

@media #{$usb-max-sm}{
  .sectionRow {
    padding-top: $token-spacing-10;
    padding-bottom: $token-spacing-20;
  }
  
  .homeSolutionsOverviewSection{
    padding-bottom: $token-spacing-10;
    padding-top: $token-spacing-0;
  }
  
  .testimonialSection{
    padding-top: $token-spacing-0;
    padding-bottom: $token-spacing-0;
  }
}
