@import '@/styles/theme/variables.scss';

.browseDocs {
  width: 100%;
  background-color: $token-utility-white;
  padding: $token-spacing-32px $token-spacing-0 $token-spacing-32px $token-spacing-0;
  border-radius: $token-spacing-2;
  border: $token-spacing-xs;

  .browserDocsTitle {
    font-size: $token-spacing-32px;
    font-weight: $token-font-weight-med;
    line-height: $token-spacing-40px;
    letter-spacing: -0.01em;
    text-align: left;
    color: $token-utility-black;
    text-align: center;
  }

  .browseDocsDescription {
    font-size: $token-spacing-16px;
    font-weight: $token-font-weight-med;
    line-height: 23.2px;
    letter-spacing: 0.01em;
    text-align: left;
    color: $token-utility-black;
    margin-top: $token-spacing-16px;
    text-align: center;
  }

  .browseDocsCtaLink {
    display: flex !important;
    justify-content: center !important;
    margin-top: $token-spacing-32px;
  }
}