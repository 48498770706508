@import '@/styles/theme/variables.scss';

.nonSupportedAreaWrapper {
    background: $nonSupportedAreaWrapper-bgcolor !important;

    .heading {
        font-size: $token-spacing-8;
        font-weight: $button-text-font-weight;
        line-height: $token-spacing-10;
        letter-spacing: -0.01em;
        text-align: left;
        color: $token-background-primary;
        margin-top: $token-spacing-16;
    }

    .nonSupportedAreaContent {

        background: $token-color-base-dark-blue-gray !important;
        margin-bottom: $token-spacing-16;
        border-radius: $token-spacing-2;

        .headingWrapper {
            margin-top: $token-spacing-8;

            .subheading {
                font-size: $token-spacing-5;
                font-weight: $token-font-weight-reg;
                line-height: $token-spacing-8;
                text-align: left;
                color: $token-background-primary;
                margin-bottom: $token-spacing-4
            }

            .description {
                font-size: $token-spacing-5;
                font-weight: $token-font-weight-reg;
                line-height: $token-spacing-8;
                text-align: left;
                color: $token-background-primary;
            }
        }

        .countryCardListWrapper {
            .regionName {
                font-size: $token-spacing-4;
                font-weight: $button-text-font-weight;
                line-height: 23.2px;
                letter-spacing: 0.01em;
                text-align: left;
                color: $token-background-primary;
                margin-bottom: $token-spacing-4;
            }

            .countryCardList {
                display: flex;
                flex-wrap: wrap;

                .countryCard {
                    display: flex;
                    flex-wrap: nowrap;
                    align-items: center;
                    padding: $token-spacing-3 $token-spacing-4 $token-spacing-3 $token-spacing-4;
                    gap: $token-spacing-2;

                    :hover {
                        cursor: pointer;
                    }

                    .countryCardText {
                        font-size: $token-spacing-4;
                        font-weight: $token-font-weight-reg;
                        line-height: 25.6px;
                        letter-spacing: 0.01em;
                        text-align: left;
                        color: $token-background-primary;
                    }
                }
            }
        }

        .contactUsSection {
            .contactUsCard {
                width: 100%;
                display: flex;
                flex-direction: column;
                background: $token-background-primary !important;
                align-items: center;
                gap: $token-spacing-10px;
                border-radius: $token-spacing-2;
                margin-bottom: $token-spacing-8;
                padding: $token-spacing-4;

                .contactUsText {
                    font-size: $token-spacing-16px;
                    font-weight: $button-text-font-weight;
                    line-height: 23.2px;
                    letter-spacing: 0.01em;
                    text-align: left;
                    color: $token-utility-black;
                }
            }
        }
    }
}