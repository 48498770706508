@import '@/styles/theme/variables.scss';

.mainContainer {
  height: auto;
  min-height: auto;
}

.header {
  margin-top: $token-spacing-20;
  display: flex;
  h1 {
    font-style: normal;
    font-weight: 700;
    font-size: $token-font-size-3xl;
    color: $token-background-inverse-primary;
  }
}
