@import '@/styles/theme/variables.scss';

.testimonialImage {
  width: 100%;
}

.testimonialImageWrapper {
  width: 100%;
  padding-top: $token-spacing-4;
}

.textBlock {
  padding: $token-spacing-4 $token-spacing-8;
}

.sectionRow {
  padding-top: $token-spacing-10;
  padding-bottom: $token-spacing-10;
}

.headH2 {
  @include headH2;
}

.paragraph {
  font-size: $token-font-size-base;
  font-weight: $token-font-weight-med;
  line-height: $token-line-height-loose;
  margin-bottom: $token-spacing-2;

  ul {
    @include listUl;
    padding-inline-start:$token-spacing-10;
  }

  p {
    @include paragraphP;
  }
}

.headH3 {
  margin-bottom: $token-spacing-6;
  font-size: $token-font-size-xl;
  font-weight: $token-font-weight-med;
  line-height: $token-line-height-30px;
}

.headH3 p{
  font-weight: $token-font-weight-med;
}
// Smaller than LG screen size
@media #{$usb-max-lg} {
  .headH3 {
    margin-bottom: $token-spacing-6;
  }

  .paragraph {
    margin-bottom: $token-spacing-0 !important;

    ul {
      margin-bottom: $token-spacing-0;
    }
  }
}

@media #{$usb-max-sm} {
  .textBlock {
    padding: $token-spacing-4 $token-spacing-0;
  }
}

@media (min-width: $token-breakpoint-x-large) { 
  .gridWrapper {
    max-width:$token-spacing-1650px !important;
  }
}
